<template>
    <div>
        <front-header :positionShow="true" :chooseTitle="'employer'"></front-header>
        <div class="box-content">
            <div>
                <img :src="baseUrl+detaildata.right_img" :alt="detaildata.productName">
                <div>
                    <h2>{{detaildata.productName}}</h2>
                    <div>行业超低价 | {{detaildata.company}}承保 | 不限厂商品牌</div>
                    <div>
                        1、万元以下赔款当天到账 <br> 2、误工费补贴，住院出院都可赔 <br> 3、 社保范围0免赔，100%赔付 <br> 4、 法律费用也可赔，不怕法律纠纷 <br>
                        5、可选附加24小时意外，非工作期间也能保
                    </div>
                    <el-button type="primary" class="box-content-first-button" @click="buyNow()">立即投保</el-button>
                </div>
            </div>
            <div class="box-content-second">
                <div class="box-content-second-nav" :class="{'fixed':isfixed}">
                    <div :class="act==1?'activate':''" class="nav-btn" @click="activate(1)">保险详情</div>
                    <div :class="act==2?'activate':''" class="nav-btn" @click="activate(2)">常见问题</div>
                    <div :class="act==3?'activate':''" class="nav-btn" @click="activate(3)">保险责任</div>
                </div>
                <div class="box-content-second-con">
                    <insurance-detail v-if="act==1" :buy="detaildata.buy"
                                      :pc_detail_img="detaildata.pc_detail_img"></insurance-detail>
                    <faq v-if="act==2" :question="detaildata.question"></faq>
                    <insuranceLiability v-if="act==3" :instructions="detaildata.instructions"
                                        :clause="detaildata.clause"
                                        :special_appiontment="detaildata.special_appiontment"></insuranceLiability>
                </div>
            </div>
        </div>
        <nonsupport ref="nosupport"></nonsupport>
        <front-bottom></front-bottom>
    </div>
</template>

<script>
    import FrontHeader from '../../components/front/frontHeader'
    import InsuranceDetail from "../../components/front/InsuranceDetail";
    import faq from "../../components/front/faq";
    import insuranceLiability from "../../components/front/insuranceLiability";
    import nonsupport from "../../components/back/dialog/nonsupport";
    import frontBottom from "../../components/front/frontBottom";

    export default {
        name: "detail",
        components: {
            FrontHeader,
            InsuranceDetail,
            faq,
            insuranceLiability,
            nonsupport,
            frontBottom
        },
        data() {
            return {
                act: 1,
                isfixed: false,
                topheight: 115,
                pro_num: "",
                work_id: "",
                detaildata: [],
                baseUrl: "",
            }
        },
        created() {
            this.pro_num = this.$route.query.pro_num
            this.work_id = this.$route.query.work_id
            console.log(this.$route.query)
            this.baseUrl = this.$store.state.baseUrl
        },
        mounted() {
            // 事件监听滚动条
            window.addEventListener("scroll", this.watchScroll);
            this.getWorkTypePC(this.pro_num, this.work_id)
        },

        destroyed() {
            // 移除事件监听
            window.removeEventListener("scroll", this.watchScroll);
        },
        methods: {
            // 选项卡切换
            activate: function (e) {
                console.log(e)
                this.act = e
                this.$refs.box.scrcllTop = 100
            },
            // 选项卡吸顶
            watchScroll() {
                // 容器的高度
                var offsetTop = document.querySelector(".box-content-second-con").getBoundingClientRect().top;
                //  滚动的距离如果大于了元素到顶部的距离时，实现吸顶效果
                if (offsetTop < this.topheight) {
                    this.isfixed = true
                } else {
                    this.isfixed = false
                }
            },
            // 加载数据
            getWorkTypePC: function (pro_num, work_id) {
                let that = this;
                let loading = that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                that.axios({
                    url: this.$store.state.baseUrl + '/api/product/newproduct/getWorkTypePC',
                    method: 'POST',
                    params: {
                        pro_num: pro_num,
                        work_id: work_id
                    }
                }).then(function (res) {
                    console.log(res)
                    loading.close();
                    if (res.data.code == 200) {
                        that.detaildata = res.data.data
                        console.log(that.detaildata)
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            // 立即投保
            buyNow: function () {
                let that = this;
                if(+that.$store.state.loginEquipment===0){
                    let login_type = that.$store.state.login_type
                    console.log(login_type)
                    if (login_type != 1) {//没有登陆转跳登陆
                        that.$router.push({
                            name: 'login',
                            query: {
                                pro_num: that.pro_num,
                                work_id: that.work_id
                            }
                        })
                    } else {//已登陆转跳投保页
                        that.$router.push({
                            name: 'insure',
                            query: {
                                pro_num: that.pro_num,
                                work_id: that.work_id
                            }
                        })
                    }
                }else{
                    that.$refs.nosupport.open()
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    $fontFamily: PingFang SC;
    @media screen and (max-width: 900px) {
        .box-content {
            width: 100vw !important;
            margin-left: 0 !important;

            & > div:nth-child(1) {
                img {
                    max-width: 100vw;
                }
                & > div:nth-child(2) {
                    &> h2 {
                        width: 100vm;
                    }
                }
            }
        }
        .box-content-second-nav.fixed {
            top: 50px!important;
            left: 50%!important;
            transform: translateX(-50%);
            width: 100% !important;
        }
    }
    @media screen and (min-width: 900px) {
        .box-content {
           & > div:nth-child(1) {
                & > div:nth-child(2) {
                    &> h2 {
                        width: 30rem
                    }
                }
            }
        }
    }
    .box-content {
        width: 70%;
        margin-left: 15%;
        padding-top: 66px;

        & > div:nth-child(1) {
            display: flex;
            justify-content: flex-start;
            margin-top: 2rem;
            flex-wrap: wrap;
            align-items: flex-start;

            & img {
                width: 27rem;
                margin-bottom: 15px;
            }

            & > div:nth-child(2) {
                text-align: left;
                margin-left: 1.5rem;

                 &> h2 {
                    height: 2.1rem;
                    font-size: 24px;
                    font-family: $fontFamily;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    padding: 0;
                    margin: 0;
                }

                & > div:nth-child(2) {
                    width: 264px;
                    height: 16px;
                    font-size: 14px;
                    font-family: $fontFamily;
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                    line-height: 18px;
                }

                & > div:nth-child(3) {
                    margin-top: 20px;
                    width: 317px;
                    height: 137px;
                    font-size: 16px;
                    font-family: $fontFamily;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 30px;
                }

                .box-content-first-button {
                    width: 203px;
                    height: 45px;
                    background: rgba(22, 84, 178, 1);
                    margin-top: 20px;
                }
            }
        }

        .box-content-second {
            position: relative;
            margin-top: 60px;
            overflow: hidden;

            .box-content-second-nav.fixed {
                position: fixed;
                top: 67px;
                left: 50%;
                transform: translateX(-50%);
                width: 70%;
                z-index: 3;
            }

            .box-content-second-nav {
                background: rgba(233, 233, 233, 1);
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 2px;

                .nav-btn {
                    width: 170px;
                    text-align: center;
                    font-size: 18px;
                    padding: 15px;
                    color: rgba(51, 51, 51, 1);
                    line-height: 18px;
                    cursor: pointer;
                    position: relative;
                }

                .nav-btn.activate {
                    background: #fff;
                }

                .nav-btn.activate::before {
                    content: "";
                    width: 100%;
                    height: 4px;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(21, 83, 177, 1);
                }
            }

            .box-content-second-nav.fixed + .box-content-second-con {
                padding-top: 48px
            }
        }
    }
</style>
