<template>
    <div class="insuranceLiability-box">
        <div class="insuranceLiability-item">
            <div class="item-tit">保险条款及须知</div>
            <div class="item-content">
                <p><a :href="baseUrl+instructions" target="_blank">投保须知及说明</a></p>
                <p v-for="(item,index) in clause" :key="index"><a :href="baseUrl+item.url" target="_blank">{{item.name}}</a></p>
                
            </div>
        </div>
        <div class="insuranceLiability-item">
            <div class="item-tit">特别约定</div>
            <div class="item-content" v-html="special_appiontment">
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        name: "insuranceLiability",
        props: {
            instructions:{
              default:""  
            },
            clause:{
              default:[]  
            },
            special_appiontment:{
              default:''  
            },
        },
        data() {
            return {
            
            }
        },
        created() {
            this.baseUrl=this.$store.state.baseUrl
        },
        methods: {
            
        }
    }
</script>

<style scoped lang="scss">
    $fontFamily: PingFang SC;
    .insuranceLiability-box{
        padding:30px;
    }
    .insuranceLiability-item{
        text-align: left;
        margin-bottom: 30px;
    }
    .insuranceLiability-item p{
        margin: 0;
    }
    .insuranceLiability-item .item-tit{
        color: #000;
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 16px;
    }
    .insuranceLiability-item .item-content{
        color: #333;
        margin-bottom: 10px;
        line-height: 2;
        font-size: 16px;
        p a{
           text-decoration: none; 
           color:#1654b2
        }
    }
</style>
