<template>
    <div class="model">
        <el-dialog :visible.sync="dialogVisible" width="80%" :modal="true">
            <div class="dialog">
                <div>您的设备不支持此网页的浏览，建议切换到微信小程序浏览</div>
                <div>
                    <img src="../../../image/wx.jpg" alt="">
                </div>
                <div>
                    第一步：请保存上方的小程序二维码
                </div>
                <div>
                    第二步：进入微信，点击右上方的“+”按钮
                </div>
                <div>
                    第三部：选择“扫一扫”
                </div>
                <div>
                    第四部：找到此图片，并选择识别图中的小程序
                </div>
                <div>
                    第五步：进入锐鹿保小程序浏览产品
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "nonsupport",
        data() {
            return {
                dialogVisible: false
            }
        },
        methods: {
            open() {
                this.dialogVisible = true
            },
            close() {
                this.dialogVisible = false
            }
        }
    }
</script>

<style scoped lang="scss">
    div {
        box-sizing: border-box;
    }

    .model {
        .dialog {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            background: white;

            & > div:nth-child(2) {
                display: flex;
                justify-content: center;

                img {
                    width: 40vw;
                    height: 40vw;
                }
            }

            div {
                font-size: 14px;
                width: 100%;
                text-align: left;
                line-height: 25px;
            }
        }
    }
</style>
