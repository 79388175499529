<template>
    <div class="insurance-box">
        <div>
            <span>保障方案</span>
        </div>
        <div class="insurance-img">
            <img :src="baseUrl+pc_detail_img" alt="">
        </div>
        <div class="insurance-adv">
            <img src="../../image/detail/aaa.jpg" alt="">
        </div>
        <div class="insurance-why-purchase">
            <div>
                <span>大家为什么购买</span>
            </div>
            <div class="insurance-why-purchase-box">
                <el-carousel height="400px" type="card" indicator-position="none">
                    <el-carousel-item v-for="(item,index) in buy" :key="index">
                        <div class="why-purchase-box">
                            <div class="why-purchase-box-top">
                                <div class="img-box">
                                    <img :src="baseUrl+item.head" alt="">
                                </div>
                                <div class="text-box">
                                    <p>{{item.name}}</p>
                                    <p>{{item.des}}</p>
                                </div>
                            </div>
                            <p class="why-purchase-box-middle">{{item.detail}}</p>
                            <img :src="baseUrl+item.img" alt="">
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="insurance-why-purchase-box1">
                <el-carousel height="400px" indicator-position="none">
                    <el-carousel-item v-for="(item,index) in buy" :key="index">
                        <div class="why-purchase-box">
                            <div class="why-purchase-box-top">
                                <div class="img-box">
                                    <img :src="baseUrl+item.head" alt="">
                                </div>
                                <div class="text-box">
                                    <p>{{item.name}}</p>
                                    <p>{{item.des}}</p>
                                </div>
                            </div>
                            <p class="why-purchase-box-middle">{{item.detail}}</p>
                            <img :src="baseUrl+item.img" alt="">
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <div class="insurance-purchase-process">
            <div>
                <span>购买流程</span>
            </div>
            <div>———— 在线投保简单快捷 ————</div>
            <div>
                <div>
                    <div><img src="../../image/detail/icon/icon1.png" alt="">
                        <div>选择方案</div>
                    </div>
                    <div>1</div>
                </div>
                <div>
                    <div><img src="../../image/detail/icon/icon2.png" alt="">
                        <div>填写信息</div>
                    </div>
                    <div>2</div>
                </div>
                <div>
                    <div><img src="../../image/detail/icon/icon3.png" alt="">
                        <div>支付保费</div>
                    </div>
                    <div>3</div>
                </div>
                <div>
                    <div><img src="../../image/detail/icon/icon4.png" alt="">
                        <div>获取保单</div>
                    </div>
                    <div>4</div>
                </div>
            </div>
        </div>
        <div class="insurance-compensate">
            <div>
                <span>理赔流程</span>
            </div>
            <div>———— 索赔进度及时了解 ————</div>
            <div>
                <div>
                    <img src="../../image/detail/pic5.png" alt="">
                    <div>第一步</div>
                    <div>在线备案</div>
                    <div>拨打电话400-616-2199</div>
                </div>
                <div>
                    <img src="../../image/detail/pic4.png" alt="">
                    <div>第二步</div>
                    <div>提交理赔材料</div>
                    <div>理赔客服会指导您在线上交</div>
                    <div>或快递理赔材料</div>
                </div>
                <div>
                    <img src="../../image/detail/pic2.png" alt="">
                    <div>第三步</div>
                    <div>理赔材料审核</div>
                    <div>理赔材料快速审核</div>
                    <div>专业便捷</div>
                </div>
                <div>
                    <img src="../../image/detail/pic3.png" alt="">
                    <div>第四步</div>
                    <div>
                        成功获得赔款
                    </div>
                    <div>最快当天可结案并支付</div>
                    <div>到账时间可以银行实际到账为准</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "insuranceDetail",
        props: {
            buy: {},
            pc_detail_img: {
                default: ""
            }
        },
        data() {
            return {}
        },
        created() {
            this.baseUrl = this.$store.state.baseUrl
        },
    }
</script>

<style scoped lang="scss">
    @media screen and (max-width: 900px) {
        .insurance-purchase-process {
            & > div:nth-child(3) {
                margin-top: 70px;
                width: 100%;
                overflow-y: scroll;
                display: flex;
                padding-bottom: 60px;
                /*border-bottom: 6px solid #779BD2;*/
                & > div {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 30px;
                    position: relative;

                    & > div:nth-child(1) {
                        position: relative;
                        width: 148px;
                        height: 157px;
                        background: url("../../image/detail/pic6.png");
                        background-size: cover;

                        img {
                            margin-top: 30px;
                        }

                        div {
                            position: absolute;
                            bottom: 30px;
                            width: 100%;
                            text-align: center;
                        }
                    }

                    & > div:nth-child(2) {
                        position: absolute;
                        bottom: -20px;
                        width: 40px;
                        height: 40px;
                        background: rgba(255, 255, 255, 1);
                        border: 2px solid rgba(119, 155, 210, 1);
                        border-radius: 50%;
                        line-height: 40px;
                        font-size: 24px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: rgba(89, 147, 236, 1);
                        z-index: 100;
                    }
                }
            }
        }
        .insurance-why-purchase-box {
            display: none !important;
        }
        .insurance-why-purchase-box1 {
            padding: 25px 35px 30px;
            display: block !important;
        }
        .insurance-compensate {
            & > div:nth-child(3) {
                & > div {
                    margin-top: 30px;
                }
            }
        }
    }

    @media screen and (max-width: 1280px) and (min-width: 900px) {
        .insurance-purchase-process {
            & > div:nth-child(3) {
                margin-top: 70px;
                width: 95%;
                margin-left: 2.5%;
                margin-bottom: 80px;
                display: flex;
                justify-content: space-around;
                border-bottom: 6px solid #779BD2;

                & > div {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 20px;

                    & > div:nth-child(1) {
                        position: relative;
                        width: 148px;
                        height: 157px;
                        background: url("../../image/detail/pic6.png");
                        background-size: cover;

                        img {
                            margin-top: 30px;
                        }

                        div {
                            position: absolute;
                            bottom: 30px;
                            width: 100%;
                            text-align: center;
                        }
                    }

                    & > div:nth-child(2) {
                        position: absolute;
                        bottom: -20px;
                        width: 40px;
                        height: 40px;
                        background: rgba(255, 255, 255, 1);
                        border: 2px solid rgba(119, 155, 210, 1);
                        border-radius: 50%;
                        line-height: 40px;
                        font-size: 24px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: rgba(89, 147, 236, 1);
                    }
                }
            }
        }
        .insurance-compensate {
            & > div:nth-child(3) {
                margin-top: 70px;
                width: 95%;
                height: 300px;
                margin-left: 2.5%;
                display: flex;
                justify-content: space-between;

                & > div {
                    width: 23%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    & > div {
                        width: 100%;
                    }

                    & > div:nth-child(2) {
                        font-size: 18.01px;
                        margin-top: 20px;
                        font-weight: 400;
                        color: black;
                    }

                    & > div:nth-child(3) {
                        font-size: 18.01px;
                        font-weight: 400;
                        color: #1654B2;
                    }

                    & > div:nth-child(4) {
                        font-size: 16px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        margin-top: 5px;
                    }

                    & > div:nth-child(5) {
                        font-size: 16px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        margin-top: -10px;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1280px) {
        .insurance-purchase-process {
            & > div:nth-child(3) {
                margin-top: 70px;
                width: 95%;
                margin-left: 2.5%;
                margin-bottom: 80px;
                display: flex;
                justify-content: space-around;
                border-bottom: 6px solid #779BD2;

                & > div {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 20px;

                    & > div:nth-child(1) {
                        position: relative;
                        width: 148px;
                        height: 157px;
                        background: url("../../image/detail/pic6.png");
                        background-size: cover;

                        img {
                            margin-top: 30px;
                        }

                        div {
                            position: absolute;
                            bottom: 30px;
                            width: 100%;
                            text-align: center;
                        }
                    }

                    & > div:nth-child(2) {
                        position: absolute;
                        bottom: -20px;
                        width: 40px;
                        height: 40px;
                        background: rgba(255, 255, 255, 1);
                        border: 2px solid rgba(119, 155, 210, 1);
                        border-radius: 50%;
                        line-height: 40px;
                        font-size: 24px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: rgba(89, 147, 236, 1);
                    }
                }
            }
        }
        .insurance-compensate {
            & > div:nth-child(3) {
                margin-top: 70px;
                width: 95%;
                height: 300px;
                margin-left: 2.5%;
                display: flex;
                justify-content: space-between;

                & > div {
                    width: 23%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    & > div {
                        width: 100%;
                    }

                    & > div:nth-child(2) {
                        font-size: 18.01px;
                        margin-top: 20px;
                        font-weight: 400;
                        color: black;
                    }

                    & > div:nth-child(3) {
                        font-size: 18.01px;
                        font-weight: 400;
                        color: #1654B2;
                    }

                    & > div:nth-child(4) {
                        font-size: 16px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        margin-top: 5px;
                    }

                    & > div:nth-child(5) {
                        font-size: 16px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        margin-top: -5px;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .insurance-box {
        border: 1px solid rgba(22, 84, 178, 0);
        width: 100%;
        background: rgba(22, 84, 178, 0.08);

        & > div:nth-child(1) {
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #1654b2;
            line-height: 18px;
            width: 178px;
            height: 13px;
            background: rgba(22, 84, 178, 0.55);
            border-radius: 7px;
            display: block;
            margin: 0 auto;
            margin: 70px auto 30px;

            span {
                position: relative;
                top: -15px;
            }
        }

        .insurance-img {
            padding: 0 70px 60px;

            img {
                width: 100%;
                vertical-align: middle;
            }
        }

        .insurance-why-purchase {
            width: 100%;
            position: relative;
            // margin-top: 53px;
            border-top: 1px solid white;
            margin-bottom: 50px;

            & > div:nth-child(1) {
                font-size: 36px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #1654b2;
                line-height: 18px;
                width: 260px;
                height: 13px;
                background: rgba(22, 84, 178, 0.55);
                border-radius: 7px;
                display: block;
                margin: 0 auto;
                margin: 70px auto 30px;

                span {
                    position: relative;
                    top: -15px;
                }
            }

            .insurance-why-purchase-box {
                padding: 25px 35px 30px;
            }

            .insurance-why-purchase-box1 {
                display: none;
            }

            .why-purchase-box {
                height: 400px;
                padding: 35px;
                background: #fff;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
                overflow: hidden;
                box-sizing: border-box;
                border-radius: 5px;

                .why-purchase-box-top {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .img-box {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 10px;

                        img {
                            width: 100%;
                        }
                    }

                    .text-box {
                        flex-grow: 2;
                        text-align: left;

                        p {
                            margin: 0;
                            color: rgba(51, 51, 51, 1);
                        }
                    ;

                        & > p:nth-child(1) {
                            font-size: 24px;
                            margin-bottom: 5px;
                        }

                        p + p {
                            font-size: 14px;
                        }
                    }
                }

                .why-purchase-box-middle {
                    margin: 25px 0 30px;
                    text-align: left;
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 400;
                    color: #333;
                }

                .why-purchase-box-middle + img {
                    max-height: 180px;
                }
            }
        }

        .insurance-purchase-process {
            width: 100%;
            position: relative;
            // margin-top: 53px;
            border-top: 1px solid white;
            margin-bottom: 50px;

            & > div:nth-child(1) {
                position: absolute;
                top: 60px;
                // left: calc(50% - 75px);
                left: 50%;
                transform: translateX(-50%);
                font-size: 36px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(22, 84, 178, 1);
                line-height: 18px;
                width: 178px;
                height: 13px;
                background: rgba(22, 84, 178, 0.55);
                border-radius: 7px;
                display: table-cell;
                vertical-align: bottom;

                span {
                    position: relative;
                    top: -15px;
                }
            }


            & > div:nth-child(2) {
                margin-top: 100px;
            }
        }

        .insurance-compensate {
            width: 100%;
            position: relative;
            margin-top: 53px;
            border-top: 1px solid white;
            margin-bottom: 50px;

            & > div:nth-child(1) {
                position: absolute;
                top: 60px;
                // left: calc(50% - 75px);
                left: 50%;
                transform: translateX(-50%);
                font-size: 36px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(22, 84, 178, 1);
                line-height: 18px;
                width: 178px;
                height: 13px;
                background: rgba(22, 84, 178, 0.55);
                border-radius: 7px;
                display: table-cell;
                vertical-align: bottom;

                span {
                    position: relative;
                    top: -15px;
                }
            }

            & > div:nth-child(2) {
                margin-top: 100px;
            }
        }
    }
    .insurance-adv{
        width: 100%;
        border-top: 1px solid white;
        margin-bottom: 50px;
        img{
            max-width: 100%;
        }
    }

</style>
