<template>
    <div class="faq-box">
        <div class="faq-item" v-for="(item , i) in question" :key='i'>
            <p class="item-tit">{{item.name}}</p>
            <p class="item-content">{{item.content}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "faq",
         props: {
            question:{}
        },
        data() {
            return {
            }
        },
        methods: {
            
        }
    }
</script>

<style scoped lang="scss">
    $fontFamily: PingFang SC;
    .faq-box{
        text-align: left;
        padding:0 30px;
       .faq-item{
           padding: 45px 0 45px 40px;
           border-bottom:1px dotted rgba(234,234,234,1);
       }
       .faq-item p{
           margin: 0;
           position: relative;
           font-size: 16px;
           line-height: 2;
       }
       .faq-item .item-tit{
           color: #000000;
           font-weight: bold;
           margin-bottom: 25px;
       }
       .faq-item .item-content{
           color: #333;
       }
       .faq-item .item-tit::before, .faq-item .item-content::before{
            width:28px;
            height:28px;
            border-radius:3px;
            color:#fff;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top:-4px;
            left:-40px;
       }
       .faq-item .item-tit::before{
            content:"Q";
            background:rgba(255,151,27,1);
       }
       .faq-item .item-content::before{
          content:"A"; 
          background:#357BE7; 
       }
    }
    

</style>
